<template>
  <header ref="headerEl" class="sticky top-0 moving-header bg-white border-b border-slate-200 z-30">
    <div class="px-4 sm:px-6 lg:px-8">
      <div class="flex items-center justify-between h-16 -mb-px">

        <!-- Header: Left side -->
        <div class="flex">

        </div>

        <!-- Header: Right side -->
        <div class="flex items-center space-x-3">
          <NuxtLink :to="localePath('/create')"
            class="w-8 h-8 flex items-center justify-center bg-slate-100 hover:bg-purple-200 transition duration-200 rounded-full ml-2 sm:ml-3"
                    :title="$t('goto.getting.started.page')">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
              <path d="M11.25 4.533A9.707 9.707 0 0 0 6 3a9.735 9.735 0 0 0-3.25.555.75.75 0 0 0-.5.707v14.25a.75.75 0 0 0 1 .707A8.237 8.237 0 0 1 6 18.75c1.995 0 3.823.707 5.25 1.886V4.533ZM12.75 20.636A8.214 8.214 0 0 1 18 18.75c.966 0 1.89.166 2.75.47a.75.75 0 0 0 1-.708V4.262a.75.75 0 0 0-.5-.707A9.735 9.735 0 0 0 18 3a9.707 9.707 0 0 0-5.25 1.533v16.103Z" />
            </svg>
          </NuxtLink>
          <NuxtLink :to="localePath('/faqs')"
                    no-prefetch
                    class="w-8 h-8 flex items-center justify-center bg-slate-100 hover:bg-purple-200 transition duration-200 rounded-full ml-2 sm:ml-3"
                    :title="$t('goto.faqs.page')"
                    target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-6 h-6">
              <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
            </svg>
          </NuxtLink>
          <button @click="openFeedbackForm"
            class="w-8 h-8 flex items-center justify-center bg-slate-100 hover:bg-purple-200 transition duration-200 rounded-full ml-2 sm:ml-3">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
              <path d="M4.913 2.658c2.075-.27 4.19-.408 6.337-.408 2.147 0 4.262.139 6.337.408 1.922.25 3.291 1.861 3.405 3.727a4.403 4.403 0 00-1.032-.211 50.89 50.89 0 00-8.42 0c-2.358.196-4.04 2.19-4.04 4.434v4.286a4.47 4.47 0 002.433 3.984L7.28 21.53A.75.75 0 016 21v-4.03a48.527 48.527 0 01-1.087-.128C2.905 16.58 1.5 14.833 1.5 12.862V6.638c0-1.97 1.405-3.718 3.413-3.979z" />
              <path d="M15.75 7.5c-1.376 0-2.739.057-4.086.169C10.124 7.797 9 9.103 9 10.609v4.285c0 1.507 1.128 2.814 2.67 2.94 1.243.102 2.5.157 3.768.165l2.782 2.781a.75.75 0 001.28-.53v-2.39l.33-.026c1.542-.125 2.67-1.433 2.67-2.94v-4.286c0-1.505-1.125-2.811-2.664-2.94A49.392 49.392 0 0015.75 7.5z" />
            </svg>
          </button>
          <NuxtLink :to="localePath('/account')"
                    no-prefetch
            class="w-8 h-8 flex items-center justify-center bg-slate-100 hover:bg-purple-200 transition duration-200 rounded-full ml-2 sm:ml-3"
            aria-controls="search-modal"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#000000" class="w-6 h-6">
              <path fill-rule="evenodd" d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z" clip-rule="evenodd" />
            </svg>
          </NuxtLink>

        </div>
      </div>
    </div>
  </header>
</template>

<script setup>
  import {useUtilsStore} from "~/store/utils";
  import {storeToRefs} from "pinia";
  import Button from "~/components/color/button.vue";

  const utilsStore = useUtilsStore();

  const {sidebarOpen, feedbackFormDialog} = storeToRefs(utilsStore);

  const headerEl = ref(null);
  const toggleSidebar = () => {
    sidebarOpen.value = !sidebarOpen.value
  }

  const openFeedbackForm = () => {
    feedbackFormDialog.value = true;
  }


  onMounted(() => {
    const mainContentEl = document.getElementById('main-content')
    // on scroll down hide the header, on scroll up, show it again
    if (!mainContentEl || ! headerEl.value) return;

    let prevScrollpos = mainContentEl.scrollTop
    mainContentEl.addEventListener('scroll',() => {
      const currentScrollPos = mainContentEl.scrollTop;
      if (prevScrollpos > currentScrollPos) {
        headerEl.value.classList.add('scroll-down')
        headerEl.value.classList.remove('scroll-up')
      } else {
        headerEl.value.classList.add('scroll-up')
        headerEl.value.classList.remove('scroll-down')

      }
      prevScrollpos = currentScrollPos;
    });
  });

</script>

<style scoped>
  .scroll-up {
    transform: translateY(-100%);
  }

  .scroll-down {
    transform: translateY(0);
  }

  .moving-header {
    transition: transform 0.3s ease-in-out;
  }
</style>